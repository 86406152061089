/* src/styles/LandingPage.css */

body {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
}

.hero-section {
  margin-top: 150px;
  background-color: #f0f0f0;
  text-align: center;
  padding: 30px 10px;
}

.hero-section h1 {
  margin: 0;
  font-size: 2.5em;
  color: #4a0aa0;
}

.hero-section p {
  margin: 10px 0;
  font-size: 1.2em;
  color: #333333;
}

.intro-section, .services-overview {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.intro-section h2, .services-overview h2 {
  color: #4a0aa0;
}

.intro-section p, .services-overview p {
  color: #555555;
  line-height: 1.6;
}

.picture-placeholder {
  margin: 20px auto;
  width: 80%;
  height: 300px;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.picture-placeholder p {
  font-size: 1.5em;
  color: #555;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.service-item {
  position: relative;
  flex: 1 1 calc(25% - 20px);
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
}

.service-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-item img.adjust-position {
  background-position: top center; /* Adjust as needed */
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.overlay-content {
  text-align: center;
}

.overlay h3 {
  font-size: 1.5em;
  margin: 0;
}

.overlay p {
  margin: 10px 0 0;
  color: #fff;
  font-size: 1em;
}

.learn-more-btn, .contact-btn {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  background-color: #4a0aa0;
  text-decoration: none;
  border-radius: 5px;
}

.learn-more-btn:hover, .contact-btn:hover {
  background-color: #370077;
}

.explore-services-btn {
margin-top:15px;
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #4a0aa0;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.footer .contact-info {
  text-align: left;
}

.footer .contact-info h2 {
  color: #ffffff;
}

.footer .contact-info p {
  color: #cccccc;
}

.footer .rights {
  margin-right:125px;
  color: #ffffff;
}

.footer .contact-btn {
  color: #4a0aa0;
  background-color: #ffffff;
  border: 2px solid #4a0aa0;
  text-decoration: none;
  border-radius: 5px;
}

.footer .contact-btn:hover {
  background-color: #f9f9f9;
  color: #4a0aa0;
}

/* src/styles/LandingPage.css */

.service-btn {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: white;
  background-color: #4a0aa0;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1em;
  opacity: .9;
}

.service-btn:hover {
  background-color: #370077;
}


/* Responsive styles */

@media (max-width: 768px) {
  .service-btn {
    font-size: 0.9em;
    padding: 8px 16px;
  }

  .hero-section {
    margin-top: 85px;
  }
  .hero-section h1 {
    font-size: 2em;
  }

  .hero-section p {
    font-size: 1em;
  }

  .intro-section, .services-overview {
    margin: 10px;
    padding: 15px;
  }

  .picture-placeholder {
    width: 90%;
    height: 200px;
  }

  .services-list {
    flex-direction: column;
  }

  .service-item {
    flex: 1 1 100%;
    margin: 10px 0;
  }

  .footer {
    flex-direction: column;
    text-align: center;
  }

  .footer .contact-info {
    text-align: center;
  }
}


@media (max-width: 480px) {
  .hero-section {
    margin-top: 15px;
  }
  .hero-section h1 {
    font-size: 1.5em;
  }
  .footer .rights {
    margin-right:0px;
    color: #ffffff;
  }
  
  .hero-section p {
    font-size: 0.9em;
  }

  .picture-placeholder {
    width: 95%;
    height: 150px;
  }

  .picture-placeholder p {
    font-size: 1.2em;
  }
  
  .services-list {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .service-item {
    flex: 1 1 calc(50% - 10px); /* Same adjustment for small mobile screens */
    margin: 5px 0;
  }
  
  .learn-more-btn, .contact-btn {
    padding: 8px 16px;
  }

  .service-btn {
    font-size: 0.8em;
    padding: 6px 14px;
  }
}
