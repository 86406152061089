/* src/styles/AboutUsPage.css */

.about-us-content {
  max-width: 40%;
  margin: 0 auto;
  padding: 20px;
  margin-top: 150px; /* Adjust as needed */
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;;
}

.location-map {
  display: flex;
  justify-content: center; /* Centers the map horizontally */
  align-items: center; /* Centers the map vertically */
  height: 450px; /* Adjust height as needed */
  width: 450px; /* Make the width the same as the height for a square */
  background-color: #f0f0f0; /* Light grey background color for the placeholder */
  border: 2px solid #ccc; /* Optional: border to define the area */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: shadow for visual effect */
  margin: 0 auto; /* Centers the map horizontally within the parent */

}

.map-image {
  width: 100%; /* Ensures the image covers the container width */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the container without stretching */
}

.about-us-content h2 {
  color: #4a0aa0;
}

.about-us-content h3 {
  color: #333333;
}

.about-us-content p {
  color: #555555;
  line-height: 1.6;
}

.serviceability-check {
  margin-top: 30px;
}

.serviceability-check h3 {
  margin-bottom: 10px;
}

.serviceability-check input[type='text'] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.serviceability-check button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4a0aa0;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.serviceability-check button:hover {
  background-color: #370077;
}

.serviceability-check p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

/* Tablet View */
@media screen and (min-device-width : 768px) 
and (max-device-width : 1024px) {
  .about-us-content {
      max-width: 80%;
      margin-top: 140px; /* Adjust as needed */
      padding: 15px;
  }

  .about-us-content h2 {
      font-size: 28px;
  }

  .about-us-content h3 {
      font-size: 24px;
  }

  .about-us-content p {
      font-size: 20px;
  }

  .serviceability-check h3 {
      font-size: 22px;
  }

  .serviceability-check input[type='text'] {
      font-size: 18px;
      padding: 8px;
  }

  .serviceability-check button {
      font-size: 18px;
      padding: 8px 16px;
  }

  .serviceability-check p {
      font-size: 18px;
  }
}

/* Mobile View */
@media screen and (max-width: 480px) {
  .about-us-content {
      max-width: 90%;
      margin-top: 15px; /* Adjust as needed */
      padding: 10px;
  }

  .about-us-content h2 {
      font-size: 20px;
  }

  .location-map {
    display: flex;
    justify-content: center; /* Centers the map horizontally */
    align-items: center; /* Centers the map vertically */
    height: 300px; /* Adjust height as needed */
    width: 300px; /* Make the width the same as the height for a square */
    background-color: #f0f0f0; /* Light grey background color for the placeholder */
    border: 2px solid #ccc; /* Optional: border to define the area */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: shadow for visual effect */
    margin: 0 auto; /* Centers the map horizontally within the parent */
  
  }

  .about-us-content h3 {
      font-size: 18px;
  }

  .about-us-content p {
      font-size: 14px;
  }

  .serviceability-check h3 {
      font-size: 16px;
  }

  .serviceability-check input[type='text'] {
      font-size: 12px;
      padding: 6px;
  }

  .serviceability-check button {
      font-size: 12px;
      padding: 6px 12px;
  }

  .serviceability-check p {
      font-size: 12px;
  }
}

/* Small Mobile View */
@media screen and (max-width: 320px) {
  .about-us-content {
      max-width: 100%;
      margin-top: 80px; /* Adjust as needed */
      padding: 5px;
  }

  .about-us-content h2 {
      font-size: 18px;
  }

  .about-us-content h3 {
      font-size: 16px;
  }

  .about-us-content p {
      font-size: 12px;
  }

  .serviceability-check h3 {
      font-size: 14px;
  }

  .serviceability-check input[type='text'] {
      font-size: 10px;
      padding: 4px;
  }

  .serviceability-check button {
      font-size: 10px;
      padding: 4px 8px;
  }

  .serviceability-check p {
      font-size: 10px;
  }
}
