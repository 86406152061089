/* src/styles/Services.css */

.services-container{
  margin-top: 150px;

}

h2 {
  color: #4a0aa0;
}

.split-screen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.text-content {
  flex: 1;
  padding: 20px;
  color: #555555;
}

.text-content h3 {
  color: #4a0aa0;
  margin-bottom: 10px;
}

.text-content ul {
  list-style-type: none;
  padding: 0;
}

.text-content li {
  margin: 10px 0;
}

.text-content li::before {
  content: "•";
  color: #4a0aa0;
  margin-right: 10px;
}

.term {
  font-size: 1.0rem;
  font-weight: bold;
  color: black; /* Purple color for the terms */
  margin-top: 20px;
}

.description {
  font-size: 1.0rem;
  color: black;
  margin-top: 5px;
  line-height: 1.4;
}

.image-content {
  flex: 0.5;  /* Adjust this value to change the image size relative to the text */
  padding: 10px; /* Reduced padding */
}

.image-content img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .split-screen {
    flex-direction: column;
    align-items: center;
  }

  .services-container {
    margin-top: 20px;
  }

  .text-content {
    text-align: left;  /* Keeps text aligned left even on mobile */
    padding: 10px;  /* Reduce padding */
  }

  .image-content {
    margin-bottom: 20px;
  }

  .text-content li {
    font-size: 0.85rem;  /* Smaller font size on mobile */
  }
}
