/* src/styles/MobileNavBar.css */

/* General mobile navbar styles */
.mobile-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4a0aa0;
  padding: 10px;
  position: relative;
}

.logo-container {
  width: 80px; /* Adjust according to your logo size */
}

.logo {
  width: 100%;
  height: auto;
}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.hamburger-menu .bar {
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin: 3px 0;
}

.mobile-nav-menu {
  display: none;
}

.mobile-nav-menu.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #4a0aa0;
  width: 100%;
  z-index: 1000;
}

.mobile-nav-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.mobile-nav-menu li {
  padding: 10px;
}

.mobile-nav-link {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.mobile-nav-link:hover {
  background-color: #6f2bd5;
}

@media screen and (max-width: 480px) {
  .dropdown-content {
    display: none;
    margin-top:10px;
    flex-direction: column;
  }

  .dropdown:hover .dropdown-content,
  .dropdown-content.open {
    display: flex;
  }

  .dropdown-item {
    color: #fff;
    text-decoration: none;
    padding: 10px;
    transition: background-color 0.3s ease;
  }

  .dropdown-item:hover {
    background-color: #6f2bd5;
  }
}