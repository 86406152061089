.contact-us-container {
    max-width: 40%;
    margin: 0 auto;
    padding: 20px;
    margin-top: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 150px; /* Adjust as needed */
  }
  
  .contact-us-container h2 {
    margin-top: 0;
  }
  
  .contact-us-container form {
    margin-top: 20px;
  }
  
  .contact-us-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  .contact-us-container input[type="text"],
  .contact-us-container input[type="email"],
  .contact-us-container input[type="phone"],

  .contact-us-container textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .contact-us-container input[type="submit"] {
    background-color: #4a0aa0;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .contact-us-container input[type="submit"]:hover {
    background-color: #6f2bd5;
  }
  
  /* New styles for inquiries section */
.inquiries-section {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .inquiries-section h3 {
    color: #4a0aa0;
    margin-top: 0;
  }




  @media screen and (max-width: 480px) {

    .contact-us-container {
      max-width: 80%;
      margin: 0 auto;
      padding: 20px;
      background-color: #f9f9f9;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-top: 15px; /* Adjust as needed */
      margin-bottom: 20px;
    }
    
    .contact-us-container h2 {
      margin-top: 0;
    }
    
    .contact-us-container form {
      margin-top: 20px;
    }
    
    .contact-us-container label {
      display: block;
      margin-bottom: 5px;
    }
    
    .contact-us-container input[type="text"],
    .contact-us-container input[type="email"],
    .contact-us-container input[type="phone"],
  
    .contact-us-container textarea {
      width: 100%;
      padding: 8px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
    
    .contact-us-container input[type="submit"] {
      background-color: #4a0aa0;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 10px 20px;
      cursor: pointer;
    }
    
    .contact-us-container input[type="submit"]:hover {
      background-color: #6f2bd5;
    }
    
    /* New styles for inquiries section */
  .inquiries-section {
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
    }
    
    .inquiries-section h3 {
      color: #4a0aa0;
      margin-top: 0;
    }

  }

  /* For tablets and iPads in landscape mode */
  @media screen and (min-width: 600px) and (max-width: 1200px) and (orientation: landscape) {
  .contact-us-container {
    max-width: 80%;
    margin-bottom: 20px;
  }
}

/* For tablets and iPads in portrait mode */
@media screen and (min-width: 600px) and (max-width: 1200px) and (orientation: portrait) {
  .contact-us-container {
    max-width: 80%;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
  .contact-us-container {
    margin-top: 100px; /* Adjust as needed */
  }
}
