/* General desktop-navbar Styles */
.desktop-nav {
  background-color: #4a0aa0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.desktop-nav-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.desktop-nav-link {
  text-decoration: none;
  color: #fff;
  padding: 56px 10px;
  font-size: 20px;
  transition: background-color 0.3s ease;
}

.desktop-nav-link:hover {
  background-color: #6f2bd5;
}

.desktop-logo-container {
  width: 170px;
  display: flex;
  justify-content: center;
  padding-right: 250px;
}

.desktop-logo {
  width: 100%;
  height: auto;
}

.left-links,
.right-links {
  display: flex;
  align-items: center;
}

.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
}

/* Dropdown Menu */
.dropdown-content {
  margin-top: 55px;
  display: none;
  position: absolute;
  background-color: #4a0aa0;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-width: 200px;
}

.dropdown-content a {
  color: white;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.dropdown-content a:hover {
  background-color: #6f2bd5;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .desktop-nav-link {
  background-color: #6f2bd5;
}

.dropdown-content a:last-child {
  border-bottom: none;
}

/* Tablet View */
@media screen and (max-width: 768px) {
  .desktop-nav-link {
    font-size: 16px;
  }

  .logo-container {
    width: 130px;
    padding-right: 80px;
  }
}